// topBar.scss
.TopBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000; // Set a higher z-index to ensure it appears above other elements

  .center {
    ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin: 20% 25%;
        padding: 10%;

        .icon {
          font-size: 200%;
          color: #000000;
        }

        span {
          font-size: 80%;
          font-weight: 400;
          color: #14171a;
          margin-left: 1px;
        }
      }
    }
  }
}

#signOut{
    margin-left: 0;
}

h2 {
    margin: auto 30px;
}

@media (min-width: 1200px) {
    .TopBar{
        display: none;
    }
    
}