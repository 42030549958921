.BottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  
  @media only screen and (min-width: 1200px) {
    display: none;
  }

  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ul.dash_links {
      display: flex;
      justify-content: space-evenly;
      list-style-type: none;
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 100%; // Add this line to prevent the links from overflowing

      li {
        padding: 5px; // Reduce the padding to create more space
        flex-grow: 1;
        min-width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0; // Add this line to prevent the links from shrinking

        .icon {
          font-size: 24px;
          color: #000000;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          color: #14171a;
          white-space: nowrap; // Add this line to prevent text wrapping
        }
      }
    }
  }

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  box-sizing: border-box;
}