// @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Lato:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.grid_container {
  display: grid;
  grid-template-columns: auto 1fr;
  // grid-gap: 20px;
  // height: 100vh;
  margin-left: 10px;
  margin-top: 40px;

  @media only screen and (max-width: 1199px) {
    // height: calc(100vh - 80px); /* Adjust the height to account for the bottom bar */
    padding-bottom: 150px; /* Add some padding at the bottom */
  }
}

.table-container {
  background-color: #ffffff;
  padding: 20px;
}