.warehouse-manifest-layout {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .warehouse-manifest-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 159px; // This should match the width of your sidebar

    @media only screen and (max-width: 768px) {
      margin-left: 70px; // Adjust this to match the collapsed sidebar width
    }
  }
}