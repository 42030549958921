.unauthorized_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  margin-top: 100px;

  .logout_btn {
    //   background-color: lime;
    width: 150px;
    padding: 10px;
    border: none;
    background-color: teal;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 30px;
  }
}
