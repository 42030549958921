.table-container {
  height: calc(100vh - 80px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;

  &.loading {
    justify-content: flex-start;
  }

  .MuiTextField-root {
    width: 100%;
    max-width: 800px;

    @media screen and (max-width: 600px) {
      max-width: 90%;

      .MuiOutlinedInput-root {
        font-size: 14px;
      }

      .MuiOutlinedInput-input {
        padding: 10px 20px;
      }
    }
  }

  .MuiOutlinedInput-root {
    background-color: #f0f0f0; /* Add background color */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
    padding: 0 15px; /* Add some padding inside the search bar */

    .MuiOutlinedInput-notchedOutline {
      border: none; /* Remove border at all times */
    }
  }

  .MuiOutlinedInput-input {
    background-color: #f0f0f0; /* Match background color with the root element */
    padding: 10px 20px; /* Adjust padding for better spacing */
    border-radius: 8px; /* Optional: Add border radius for input field */
  }
}

@media screen and (max-width: 768px) {
  .table-container {
    padding: 10px;
  }
}

