.freightTypeSelection {
  margin-bottom: 20px;

  h3 {
    margin-bottom: 10px;
  }

  .freightOptions {
    display: flex;
    gap: 20px;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}


// Style for radio buttons
.radio-button {
  appearance: none;
  // background-color: #f4f4f4;
  // margin-right: 10px;
  // padding: 10px;
  // border-radius: 50%;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: #00fd3d ; // Change color when checked
  }

  &:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
}