.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  border-radius: 20px;
  padding: 15px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  height: 150px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    font-weight: bold;
    font-size: 1.5em;
    color: rgb(94, 94, 94);
  }

  .counter {
    font-size: 28px;
    font-weight: bold;
  }

  .link {
    font-size: 15px;
    border-bottom: 1px solid gray;
  }

  .percentage {
    display: flex;
    align-items: center;
    font-size: 14px;

    &.positive {
      color: green;
    }

    &.negative {
      color: red;
    }
  }

  .icon {
    font-size: 30px;
    padding: 5px;
    border-radius: 5px;
    align-self: flex-end;
  }
}
