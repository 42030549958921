// .invoiceUpload {
//   background-color: #fff; // Assuming a white background
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   margin-top: 20px;
//   width: fit-content; // Assuming the form should not stretch full width

//   h3 {
//     margin-bottom: 15px;
//     color: #333; // Assuming a dark font color for the heading
//     font-size: 18px; // Adjust the font size as needed
//   }

//   form {
//     display: grid;
//     gap: 10px;
//     grid-template-columns: repeat(2, 1fr); // Assuming two columns layout
//     align-items: center;

//     input[type="text"],
//     input[type="file"],
//     textarea {
//       padding: 10px;
//       border: 1px solid #ccc;
//       border-radius: 4px;
//       margin-bottom: 10px; // Space between inputs
//     }

//     textarea {
//       grid-column: span 2; // Textarea spans two columns
//       resize: vertical; // Allowing vertical resize only
//     }

//     .file-input-container {
//       grid-column: span 2; // File input spans two columns
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
      
//       .file-upload-btn {
//         display: inline-block;
//         padding: 10px 20px;
//         background-color: #f0f0f0; // Styling for the file upload button
//         border: 1px solid #ccc;
//         border-radius: 4px;
//         cursor: pointer;
//         text-align: center;
//       }
//     }

//     button[type="submit"] {
//       grid-column: span 2; // Submit button spans two columns
//       padding: 10px 0;
//       border: none;
//       background-color: #5cabff;
//       color: white;
//       border-radius: 4px;
//       cursor: pointer;
//       margin-top: 10px;

//       &:hover {
//         background-color: #4a98ff;
//       }
//     }

//     // Additional styles for the cancel button, if needed
//     button[type="button"] {
//       // Your styling for the cancel button
//     }
//   }

//   // You might want to add media queries to ensure responsiveness
//   @media (max-width: 600px) {
//     form {
//       grid-template-columns: 1fr; // Stack inputs in a single column on small screens
//       textarea,
//       .file-input-container,
//       button[type="submit"] {
//         grid-column: span 1;
//       }
//     }
//   }
// }
.success-message {
    color: green;
    /* Additional styling for success messages */
}

.error-message {
    color: red;
    /* Additional styling for error messages */
}

.invoice-upload {
  border: 1px solid #ccc; // Example border, adjust as needed
  padding: 20px;
  border-radius: 10px;
  // Add more styles as needed
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.id-field {
  border: 2px solid #ccc; // Example border style
  border-radius: 4px;
  padding: 8px 10px;
  margin: 5px 0;

  &:focus {
    border-color: #80bdff;
    outline: none;
  }
}

.upload-button {
  margin-top: 10px;
}

.profile_container {
  flex: 1; // This will make the profile_container take up the remaining space
  overflow-y: auto; // Add a vertical scrollbar if the content overflows
}