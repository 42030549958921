.customer_dash {
  display: flex;
  height: 100vh;
  overflow-y: auto;
}

.customer_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.02s ease;
}

.scrollable_content {
  flex-grow: 1;
  // overflow-y: auto;
  padding: 20px; /* Add padding as needed */
}


.content_area {
  width: 100%;
  // padding: 20px;
  box-sizing: border-box;
}

@media only screen and (max-width: 999px) {
  .customer_container {
    margin-left: 200px; // Keep the margin for the sidebar
  }
  
  .content_area {
    // padding: 10px; // Reduce padding to give more space to content
  }
}

@media only screen and (max-width: 900px) {
  .customer_container {
    margin-left: 0; // Remove margin when sidebar is hidden
  }
}