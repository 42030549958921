.product_email {
  display: flex;
  width: 100%;

  .product_email_container {
    flex: 6;

    .top {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;

      h2 {
        color: lightgray;
      }

      .email_container {
        display: flex;
        gap: 10px;
        margin-top: 30px;
        align-items: center;

        input {
          border: 1px solid grey;
          height: 30px;
        }

        .email_button {
          width: 130px;
          height: 30px;
          padding: 5px;
          border: none;
          background-color: teal;
          color: white;
          font-weight: bold;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          //   margin-top: 30px;
        }
      }
    }
  }
}
