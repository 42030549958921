.verify-email-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  // background-color: #f5f5f5;
}

.verify-email-card {
  // background-color: white;
  border-radius: 8px;
  padding: 2rem;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  animation: fadeIn 0.5s ease-in-out;

  .success-icon {
    font-size: 4rem;
    color: #00fd3d ;
    margin-bottom: 1rem;
    animation: scaleIn 0.5s ease-in-out;
  }

  h1 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    margin-bottom: 1.5rem;
  }

  .sign-in-button {
    display: inline-block;
    background-color: #00fd3d;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #00fd3d , 10%;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes scaleIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}