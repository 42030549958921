.management-sidebar {
  flex: 1;
  border-right: 0.3px solid #e1e8ed;
  min-height: 200vh;
  background-color: #fff;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;

  &__logo {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      color: inherit;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__nav {
    padding-left: 20px;
  }

  &__nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__nav-item {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    font-weight: 900;

    &:hover {
      background-color: #e6f4fe;
    }
  }

  &__nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  &__nav-icon {
    font-size: 30px;
    color: #000000;
  }

  &__nav-text {
    font-size: 20px;
    font-weight: 470;
    color: #14171a;
    margin-left: 12px;
  }

  &__footer {
    margin: 10px;
  }

  &__address {
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem;
    text-align: left;
  }

  &__address-title {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
    font-weight: bolder;
  }

  &__address-text {
    font-size: 0.5rem;
    color: var(--text-color-lighter);
    margin: 0;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
}