.verify-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  
  .verify-email-card {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    
    .email-icon {
      font-size: 4rem;
      color: #4CAF50; // Adjust color to match your brand
      margin-bottom: 1rem;
    }
    
    h1 {
      margin-bottom: 1rem;
      color: #333;
    }
    
    p {
      margin-bottom: 1rem;
      line-height: 1.5;
      color: #666;
    }
    
    .main-message {
      font-weight: bold;
      color: #333;
    }
    
    .lifestyle-message {
      font-style: italic;
      color: #4CAF50; // Adjust color to match your brand
    }
    
    .sign-in-button {
      display: inline-block;
      background-color: #4CAF50; // Adjust color to match your brand
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      text-decoration: none;
      transition: background-color 0.3s ease;
      
      &:hover {
        background-color: darken(#4CAF50, 10%);
      }
    }
  }
}