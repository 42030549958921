.shipments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  padding: 20px;
}

@media only screen and (min-width: 1000px) {
    .shipments-grid, #backtoship {
        padding-left: 200px;
    }
}



.shipment-card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 400px; /* Adjust this value if needed */
  max-width: 600px; /* Adjust this value if needed */
}

.shipment-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
}

.shipment-id {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.shipment-id p {
  margin: 0;
}

.shipment-dates {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shipment-date {
  font-size: 16px;
}

.shipment-date p {
  margin: 0;
}

.shipment-logo {
  background-color: #00ff00; /* Green background */
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.shipment-logo img {
  width: 80px;
  height: 80px;
}
