.sidebar {
  width: 159px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff; /* White background */
  padding-top: 20px;
  transition: width 0.3s;
  border: 1px solid #ddd; /* Add border on hover */
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50%; /* Adjust the width to fit within the sidebar */
    }
  }

  &__menu {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      width: 100%;

      .sidebar__menu-item {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        transition: background 0.3s;
        position: relative; /* For tooltip positioning */
        margin: 20px 0; /* Add some spacing between items */
        border-radius: 12px; /* Rounded corners for icon button */
        background-color: transparent; /* No background by default */

        .sidebar__icon {
          font-size: 24px;
          color: #333; /* Dark color for better contrast */
        }

        span {
          display: block;
          font-size: 12px;
          color: #000000; /* Dark color for better contrast */
        }

        // &:hover {
        //   background-color: #f1f1f1; /* Light grey for hover effect */
        //   border: 1px solid #ddd; /* Add border on hover */
          
        // }

        &:hover::after {
          opacity: 1; /* Show tooltip on hover */
          border: 0.5px solid #ddd; /* Add border to tooltip on hover */
        }

        &::after {
          content: attr(data-tooltip);
          position: absolute;
          left: calc(100% + 5px);
          top: 50%;
          transform: translateY(-50%);
          background-color: #333; /* Dark background for tooltip */
          color: #fff; /* White color for better contrast */
          padding: 5px 10px;
          border-radius: 12px; /* Rounded corners for tooltip */
          border: 1px solid transparent; /* No border by default */
          white-space: nowrap;
          opacity: 0; /* Hidden by default */
          transition: opacity 0.3s, border 0.3s; /* Transition for opacity and border */
          pointer-events: none;
          z-index: 1000;
          display: none; /* Hide tooltips by default */

          
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 80px; /* Reduce the width of the sidebar on smaller screens */

    .sidebar__menu-list .sidebar__menu-item {
      span {
        display: none; /* Hide text on smaller screens */
      }

      &:hover::after {
        display: block; /* Show tooltip on hover only on smaller screens */
        opacity: 1;
        /* Adjust positioning if needed */
        left: 100%; /* Position to the right of the collapsed sidebar */
        transform: translateY(-50%);
      }
    }
  }
}
