.navmenu {
    width: 250px; // Adjust the width as needed
    padding: 20px;
    background-color: #ffffff; // Main background color
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Soft shadow for depth
    border-radius: 4px;

}

.subscribe-button {
width: 100%;
margin-bottom: 20px;
// border: #f5f5f5;
}

.trends-list {
list-style: none;
padding: 0;
margin: 0;

.MuiListItem-root {
  border-bottom: 0.5px solid #eee;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #f5f5f5; // Shaded background for each item
  border-radius: 9px;



  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: none;
  }
}

.MuiListItemText-primary {
  font-weight: bold;
  color: #333;

}
}
// Media query to hide navmenu on small screens
@media (max-width: 700px) {
    .navmenu {
      display: none;
    }
  }