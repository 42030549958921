.ware_home {
  display: flex;
}

.ware_main {
  display: flex;
  flex-direction: column;
  flex: 1; /* This allows the main content to take up the remaining space next to the sidebar */
}

.ware_container {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Adjust padding as needed */
}

.ware_widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust spacing between widgets */
}

.ware_widget {
  flex: 1; /* Adjust the flex property as needed to fit your layout */
}
