.settings {
  width: 100%;
  display: flex;

  .settings_container {
    flex: 6;

    .top,
    .bottom {
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: rgb(74, 74, 74);
        font-size: 35px;
        margin: auto;
      }

      .left {
        flex: 1;

        img {
          width: 100px;
          height: 100px;
          // border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;

        .settings_form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: block; // Changed to block for better alignment with checkboxes and select
              margin-bottom: 5px;
            }

            input[type="checkbox"] {
              margin-right: 5px;
            }

            select {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }

            
          }

          button {
            border-radius: 10px;
            width: 150px;
            padding: 10px;
            border: none;
            background-color: rgb(13, 192, 55);
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
