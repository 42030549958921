.header__file {
  height: 100vh;
  position: relative; /* Ensure relative positioning for absolute children */
}

.header__start > img {
  position: absolute; /* Changed to absolute */
  object-fit: cover;
  height: 100vh;
  width: 100%;
  z-index: -1; /* Ensure the image is behind the cards */
}

.black-card-container {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the container */
  display: flex;
  justify-content: center;
  align-items: center; /* Align items vertically */
  width: 100%;
  z-index: 1; /* Ensure the cards are above the background image */
}

.black-card {
  background-color: black;
  color: white;
  padding: 50px;
  border-radius: 20px;
  margin: 0 20px;
  width: calc(100% / 5); /* 1/8th of the screen width */
  text-align: center;
}

.black-card-title {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: lime;
}

.black-card-desc {
  font-size: 16px;
  margin-top: 100px;
}

@media only screen and (max-width: 1221px) and (min-width: 600px) {
  .black-card-container {
    margin-top: 800px;
    flex-direction: column;
  }

  .black-card {
    margin: 10px 0;
    width: 60%; /* Adjust width for medium screens */
  }
}

@media only screen and (max-width: 600px) {
  .black-card-container {
     margin-top: 400px;
    flex-direction: column;
  }

  .black-card {
    width: 80%; /* Adjust for smaller screens */
  }
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text horizontally */
  padding-top: 500px; /* Adjust this margin as needed */
  margin-bottom: 100px;
}

.header-logo {
  width: 200px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensure the logo is above the background image */
}

.signup-btn {
  align-items: center; /* Center horizontally */
  background: lime;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  color: black;
  font-weight: bold;

  margin: 0 auto;
  display: block;
}
