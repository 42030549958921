.BottomBar {
  //   @media only screen and (min-width: 600px) {
  //   display: none; // Hide the sidebar on mobile screens
  // }

  padding-left: 6%;

  .center {

    ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
      

      li {
        margin: 20% 25%;
        padding: 10%;
 

        .icon {
          font-size: 200%;
          color: #000000;
        }

        span {
          font-size: 80%;
          font-weight: 400;
          color: #14171a;
          margin-left: 1px;
        }
      }

      // Use media queries to adjust margin for different screen sizes
      @media only screen and (min-width: 600px) {
        li {
          margin-right: 1%; // Adjust the margin for screens with a width of 600px or more
        }
      }

      @media only screen and (min-width: 768px) {
        li {
          margin-right: 10px; // Adjust the margin for screens with a width of 768px or more
        }
      }

      // Add more media queries as needed for other screen sizes
    }
  }
}

#signOut {
    margin: 0;
    margin-left: 70%;
}
