.userInfo {
    margin-top: 100px;
}

// @media (max-width: 600px) {
//     #details {
//         font-size: 4%;
//     }
// }

@media screen and (min-width: 1200px) {
  .profile_container {
    padding-left: 200px;
  }
  
}