.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 24px;
  padding: 20px;
  outline: none;
  border-radius: 8px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.title {
  text-align: center;
}

.body {
  margin-top: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.productIdDisplay {
  margin-top: 16px;
}

.id-field {
  width: 100%;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #f5f5f5;
}

/* Improve the layout of the upload list */
.ant-upload-list-picture {
  display: flex;
  flex-wrap: wrap;
}

.ant-upload-list-picture .ant-upload-list-item {
  width: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.ant-upload-list-picture .ant-upload-list-item-name {
  flex: 1;
  margin-left: 8px;
}

.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
  transform: none;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}

.custom-modal .ant-modal-content {
  max-height: 90vh;  /* Adjust as needed */
  display: flex;
  flex-direction: column;
}

.custom-modal .ant-modal-body {
  flex-grow: 1;
  overflow-y: auto;
}