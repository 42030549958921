.view_cust {
  display: flex;
  width: 100%;

  .viewCust_container {
    flex: 6;

    // .top {
    //   // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    //   box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    //   padding: 10px;
    //   margin: 20px;
      

    //   // h2 {
    //   //   color: rgb(74, 74, 74);
    //   //   font-size: 35px;
    //   //   margin: auto;
    //   // }
    // }

    // .customer-table {
    //   -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    //   box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    //   margin: 20px;
    // }
  }
}
