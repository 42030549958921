.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;

  .shipButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px solid rgba(0, 0, 139, 0.596);
    cursor: pointer;
  }

  .manifestButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: green;
    border: 1px solid greenyellow;
    cursor: pointer;
  }
}

// .search-bar {
//   border: 0.1px solid;
//   padding-top: 10px;
//   padding-bottom: 10px;
//   padding-left: 30px;
//   padding-right: 100px;
//   border-radius: 5px;
//   margin-bottom: 10px;
//   margin-top: 10px;
//   margin-left: 10px; 
//   width: 600px;
// }



@media screen and (min-width: 1200px){
  .table-content{margin-left: 20px;}
  
}

.table-container {
  // margin-left: 200px;
  padding: 20px;
  transition: margin-left 0.3s;
  font-family: 'lato';
  margin-bottom: 80px; /* Add margin at the bottom to make space for the bottom bar */
  height: calc(100vh - 80px); /* Set the height to fill the remaining viewport height */
  overflow-y: auto; /* Enable vertical scrolling for the table */
}

@media screen and (max-width: 700px) {
  .table-container {
    margin-left: 50px;
    margin-left: 50px;
    margin-bottom: 80px; /* Add margin at the bottom to make space for the bottom bar */
  }
}