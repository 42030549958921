

.logo > img {
  height: 100px;
  width: 150px;
}

:root {
  --mainColor: white;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:after,
nav a:before {
  content: "";
  position: absolute;
  display: block;
  border: 0px solid transparent;
}

nav a:after {
  width: 0%;
  height: 25px;
  /* border-top: 2px solid lime; */
  border-bottom: 3px solid lime;
  transition: all 0.3s ease;
}

nav a:before {
  width: 120%;
  height: 0%;
  /* border-left: 2px solid lime; */
  /* border-right: 2px solid lime; */
  transition: all 0.5s ease;
}

nav a:hover::before {
  height: 80%;
}

nav a:hover::after {
  width: 120%;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.track-btn {
  background: lime;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: bold;
}

.login__btn {
  padding: 10px 10px;
  border-radius: 5px;
  border: 2px solid lime;
  background: none;
  /* color: white; */
  /* font-weight: bold; */
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    /* z-index: -1; */
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

a {
  text-decoration: none;
  /* display: inline-block; */
  position: relative;
  color: black;
}

button.mobile-menu-icon {
  border: none;
  background: none;
  font-size: 20px;
}

button.login-btn {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 20px 20px;
  border: 2px solid #90ee90 !important;
  border-radius: 5px;
  background: none;
  height: 20px;
  font-size: 15px;
}

.mobile-menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    line-height: 15px;
  }
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block !important;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
  }

  ul li {
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }

  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
}
