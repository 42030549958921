// @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Lato:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap");

.Sidebar {
  flex: 1;
  border-right: 0.3px solid #e1e8ed;
  min-height: 200vh;
  background-color: #fff;
  width: 200px;
  position:fixed;
  top: 0; /* Aligns the sidebar to the top of the viewport */
  left: 0; /* Aligns the sidebar to the left of the viewport */
  height: 100vh; /* This makes the sidebar full height */
  overflow-y: auto; /* Adds scroll to the sidebar if content overflows */
  z-index: 100; 
  // font-family: 'Schibsted Grotesk', sans-serif !important; // Apply Poppins locally to the Sidebar

  .logo {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      color: inherit;
    }

    .logo {
      font-size: 25px;
      font-weight: bold;
      color: #090909; // Twitter's primary color
    }
  }

  hr {
    height: 0;
    border: 0.5px solid #e1e8ed; // Twitter's border color
  }

  .center {
    padding-left: 20px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 14px; // Adjust as needed
        font-weight: bold;
        color: #657786; // Twitter's text color
        margin-bottom: 5px;
        margin-top: 15px;
        
      }

      li {
        display: flex;
        align-items: center;
        padding: 20px; // Adjust as needed
        cursor: pointer;
        font-weight: 900;

        &:hover {
          background-color: #e6f4fe; // Twitter's hover background color
        }

        .icon  *{
          font-size: 30px; // Adjust as needed
          color: #000000; // Twitter's primary color

        }

        span {
          font-size: 20px; // Adjust as needed
          font-weight: 470;
          color: #14171a; // Twitter's text color
          margin-left: 12px; // Adjust as needed
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #e1e8ed; // Twitter's border color
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: #f5f8fa; // Twitter's color
      }

      &:nth-child(2) {
        background-color: rgb(0, 0, 0); // Twitter's primary color
      }

      &:nth-child(3) {
        background-color: #e1e8ed; // Twitter's border color
      }
    }
  }
  .address {

    background-color: #f0f0f0; // light grey background color
    padding: 1rem; // add padding to create some space around the address
    border-radius: 8px; // optional: adds rounded corners if that matches your design
    margin-top: 1rem; // space above the address block
    text-align: left; // align text to the left
    
    .title {
      font-size: 01rem;
      color: var(--text-color); // Replace with your color variable or use a hex color code
      margin-bottom: 0.5rem;
      font-weight: bolder;
    }
    
    p {
      font-size: 0.5rem;
      color: var(--text-color-lighter); // Slightly lighter text color for the address
      margin: 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    display: none; // Hide the sidebar on mobile screens
  }
}


.tabs {
  width: 600px;
}