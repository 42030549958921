.name-header {
  background-color: #00fd3d; /* Set the background color to pink */
  color: black; /* Set the text color to white for contrast */
  border-radius: 16px; /* Make the edges rounded */
  width: 20em; /* Make it occupy the full width */
  height: 80%;
  font-size: 3rem; /* Set a font size */
  box-sizing: border-box; /* Ensure padding doesn't affect the width calculation */
  margin-left: 60px; /* Center the header */
  margin-right: auto;
  margin-bottom: 40px;
  padding-top: 70px;
  transform: translateX(-0.5%);
  z-index: 0;
  position: relative;
  grid-area: 1 / 1; 
}

.name-header {
  padding-left: 10px; /* Default for extra-small screens */
}

@media (max-width: 768px) {
  .name-header {
    padding-left: 30px; /* For small screens */
    transform: translateX(-12.5%);
    width: 6.2em;
  }

  .customer-image {
    width: 12em;
    height: 14em;
    transform: translate(110%, -64%);
    // margin-left: -40px;
  }
}

@media (min-width: 600px) {
  .name-header {
    padding-left: 30px; /* For small screens */
  }
}

// @media (min-width: 960px) {
//   .name-header {
//     padding-left: 30px; /* For small screens */
//     transform: translateX(1.5%);
//     width: 15em;
//   }

//   .customer-image {
//     width: 12em;
//     height: 14em;
//     transform: translate(110%, -64%);
//     // margin-left: -40px;
//   }
// }


.name-header h4, .name-header h5 , .name-header h6 {
  width: 75%; /* Remove default margin */
} 

.container-wrapper {
  // width: 90%; /* Shared width for both sections */
  // max-width: 1200px; /* Consistent max width */
  margin: 0 auto;
}

.header {
  display: grid; /* Use grid layout to overlap elements */
}


.customer-image {
  position: relative;
  z-index: 3;
  top: 50%;
  width: 12em;
  height: 14em;
  padding-bottom: 0;
  grid-area: 1 / 1; 
  // margin-right: 40px;
  // transform: translate(-9%, -18%);
}

@media (min-width: 768px) {
  .customer-image {
    width: 14.5em;
    height: 16.5em;
    transform: translate(380%, -70%);
  }
}

// @media (max-width: 400px) {
//   .customer-image {
//     width: 11em;
//     height: 11em;
//     transform: translate(-10%, 20%);
//     // margin-left: -40px;
//   }
// }

.overview,
.overseas-warehouse-addresses {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.shipping-heading {
  text-align: center; /* Default centering for all screen sizes */
  // transform: translateX(0); /* Default no translation */
}

@media (min-width: 768px) {
  .shipping-heading {
    text-align: left; /* Align text to the left on larger screens */
    // margin-left: 200px;
  }
}

.overview .MuiPaper-root,
.overseas-warehouse-addresses .MuiPaper-root {
  padding: 16px;
  border-radius: 8px;
  // text-align: center;
}

@media (max-width: 768px) {
  .overview .MuiPaper-root,
  .overseas-warehouse-addresses .MuiPaper-root {
    max-width: 100%;
  }
}

.overview {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  gap: 16px;
  width: 90%; /* Full width */
  max-width: 1200px; /* Limit maximum width */
  margin: 0 auto; /* Center the overview section */
}

.overview .MuiPaper-root {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px; /* Make the edges rounded for Paper */
  width: 100%; /* Ensure each item takes full width of container */
  max-width: 300px; /* Limit max width for each item */
  box-sizing: border-box;
  // text-align: center; /* Center the content inside Paper */
}

@media (max-width: 768px) {
  .overview .MuiPaper-root {
    max-width: 100%; /* Make each item 100% width on smaller screens */
  }
}

@media (max-width: 480px) {
  .overview .MuiPaper-root {
    padding: 12px; /* Adjust padding for small screens */
  }
}
