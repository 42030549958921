.Sidebar {
  flex: 1;
  border-right: 0.5px solid #000000;
  min-height: 100vh;
  background-color: #ffffff;
  left: 0;

  .logo {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      color: inherit;
      display: flex;
      justify-content: center;
    }

    img {
      margin-top: 10px;
      max-width: 100%;
      max-height: 100%;
      // object-fit: contain;
    }
  }

  hr {
    height: 0;
    border: 0.1px solid #000000;
  }

  .center {
    padding-left: 20px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 100px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 5px;
        margin-top: 15px;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        cursor: pointer;

        &:hover {
          background-color: #e6f4fe;
        }

        &.disabled {
          color: #657786;
          cursor: not-allowed;
        }

        .icon {
          font-size: 20px;
          color: #000000;
        }

        .fa-icon {
          font-size: 40px;
          color: #1da1f2;
        }

        span {
          font-size: 100px;
          font-weight: 600;
          color: #14171a;
          margin-left: 12px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #e1e8ed;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: #f5f8fa;
      }

      &:nth-child(2) {
        background-color: #1da1f2;
      }

      &:nth-child(3) {
        background-color: #e1e8ed;
      }
    }
  }
}