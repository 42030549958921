@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Lato:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.customer_dash {
  display: flex;
  // width: 100%;
  margin: 0px; // Reset any margins
  padding: 0; // Reset any padding
  // box-sizing: border-box; 
  // font-family: 'Lato' !important;
}

.customer_container {
  flex: 6;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  box-sizing: border-box; 
  align-items: stretch; 
}

.customer_container .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: backdrop-filter 0.3s;

  &.scrolled {
    backdrop-filter: blur(10px);
    border-bottom: 0.1px solid #f5f5f5;
  }
  
  .tab {
    flex: 1;               // Each button takes equal space
    text-align: center;    // Center the text inside each button
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;        // Provide some space between buttons

    &:hover {
      background-color: #f0f0f0;
    }

    &.active {
      border-bottom: 4px solid #00fd3d;
    }
  }
}


.tab-line {
  height: 1px;
  background-color: #e1e8ed;
  width: 100%;
  margin-bottom: 20px;
}

.content_area {
  overflow-y: auto; // Enable vertical scrolling
  border-radius: 0.5px;
  margin-bottom: 100px;
  width: 100%;
  // padding: 40px;
}

.package_tweet {
  background-color: #fff;
  padding: 20px 25px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  border: 0.1px solid #d3d3d3;
  margin-top: 40px;
  margin-bottom: 40px;

  &:hover {
    background-color: #f5f5f5;
    border: none;
  }

  .package_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .package_status {
      font-size: xx-small;
      font-weight: bolder;
      padding: 6px;
      width: 100px;
      height: 80%;
      text-align: center;
      border-radius: 20px;
      // color: white;
      // background-color: #14171a;
    }

    .received { color: #000000; background-color: #FFEB3B;  }
    .shipped { color: #000000; background-color:#FFEB3B;}
    .delivered { color: #000000; background-color: #00d832 }
    .delivery { color: #000000; background-color: #00d832;}
    .pickedUp { color: #000000;background-color:  #00d832;}
    .sentOff { color: #000000; background-color:#FFEB3B;  }
    .landed { color: #000000; background-color: #FEF8A0; }
    .ready { color: #000000; background-color: #FFEB3B; }
    .processing { color: #000000; background-color: #FFEB3B; }
    .takenOff{ color: #000000; background-color: #FFEB3B;}
    .transit{ color: #000000; background-color: #FFEB3B;}
    .ready{ color: #000000; background-color: #FFEB3B;}
    .pickupReady{ color: #000000; background-color: #FFEB3B;}
    

    .package_delivery_date {
      color: #657786;
      font-size: 0.85em;
    }
  }

  .supplier_name {
    font-size: 0.75em;
  }

  .package_description {
    // color: #14171a;
    font-size: 0.95em;
  }
}

@media (min-width: 1000px) {
  .customer_dash{
    padding-left: 200px;
  }
}

// @media (max-width: 768px) {
//   .customer_container {
//     padding: 10px;
//   }

//   .content_area {
//     max-width: 100%;
//   }

//   .package_tweet {
//     padding: 8px 10px;
//     display: flex;
//     justify-content: space-between;

//     .package_header {
//       flex-direction: column;
//       // align-items: start;

//       .package_tracking_number,
//       .package_delivery_date {
//         font-size: 0.9em;
//       }
//     }

//     .package_description {
//       font-size: 0.9em;
//     }
//   }
// }

.package_tweet > div {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  width: 100%; /* Ensures the div takes the full width */
  margin-top: 10px; /* Optional: adds space above the button */
}

// .content_area div {
//   display: flex;
//   justify-content: center;
// }


.BottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e1e8ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  .center {
    ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin-right: 15px;

        .icon {
          font-size: 20px;
          color: #000000;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #14171a;
          margin-left: 5px;
        }
      }
    }
  }
}

.uploadButton {
  border: 1.5px solid #00fd3d;
  padding: 1.7em;
  width: 50%;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
  font-size: x-small;
  cursor: pointer; /* Indicates clickable */

  &.uploaded {
    background-color: #00d832;
    color: #000000;
  }

  &:hover {
    background-color: white;
    color: #00d382;
  }
}

.no-pkg {
  // font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 90%;
  max-width: 1000px;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}



.responsive_stack {
  --stack-spacing: 1rem;
}

@media (max-width: 600px) {
  .responsive_stack {
    --stack-spacing: 0.5rem;
  }

  .package_description, .package_delivery_date {
    font-size: 0.9rem;  /* Smaller font size for better readability on small screens */
  }
}

