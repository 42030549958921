.dash_home {
  display: flex;
  min-height: 100vh;

  .dashContainer {
    flex: 6;
    overflow: auto; // Adds scroll to dashContainer if content overflows
    padding-bottom: 60px; // Adjust based on the height of your BottomBar
    

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;


      // Add a media query for mobile devices
      @media screen and (max-width: 768px) {
        display: block; // or flex with flex-direction set to column
        align-items: center; // optional, to center-align the widgets
      }
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  .dashContainer {
    margin-left: 200px;
  }
  
}