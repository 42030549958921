.add_shipment {
  display: flex;
  width: 100%;

  .addShip_container {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: rgb(74, 74, 74);
        font-size: 35px;
        margin: auto;
      }
      

      .left {
        flex: 1;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;

        .new_form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              font-weight: bold;
              gap: 10px;
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }

          button {
            border-radius: 10px;
            width: 150px;
            padding: 10px;
            border: none;
            background-color: rgb(13, 192, 55);
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }

        .label_button {
          border-radius: 10px;
          width: 100%;
          padding: 10px;
          border: none;
          background-color: rgb(13, 192, 55);
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 20px;
        }
      }
    }
  }
}
