.new_products {
  width: 100%;
  display: flex;

  .product_container .right {
    max-width: 800px; /* Adjust this value as needed */
    margin: 0 auto; /* Centers the form in the product_container */
  }

  .product_container {
    flex: 2; /* Ensure the container takes the remaining space */
    padding: 10px; /* Add padding to avoid overlap */
    margin-left: 18%; /* Adjust this value to the width of your sidebar */
    margin-right: 6%; /* Adjust this value to the width of your sidebar */

  }

  .product_container {
    // flex: 6;
    padding-bottom: 200px;

    .top,
    .bottom {
      // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: rgb(74, 74, 74);
        font-size: 35px;
        margin: auto;
      }

      .left {
        flex: 1;
        margin-top: 60px;
        margin-left: 20px;

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          margin-left: 20%;
        }

        p {
          margin-left: 20px;
          margin-top: 10px;
        }
      }

      .right {
        flex: 2;

        .new_form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;
            margin-bottom: 10px;

            label {
              display: block;
              align-items: center;
              font-weight: bold;
              // gap: 10px;
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }

          button {
            margin-left: 120px;
            width: 150px;
            padding: 10px;
            border: none;
            background-color: rgb(13, 192, 55);
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
            border-radius: 10px;
          }
        }

        .view_products {
          border-radius: 10px;
          width: 100%;
          padding: 10px;
          border: none;
          background-color: rgb(13, 192, 55);
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 50px;
          display: flex;
          justify-content: center;
        }

        .view_email {
          width: 100%;
          padding: 10px;
          border: none;
          background-color: teal;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 30px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}


.textAreaHoverEffect {
  width: 100%;
  max-width: 100%;
  max-height: 600px;
  padding: 10px;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  resize: both;
  // fontFamily: 'Arial, sans-serif';
  // fontSize: '16px';
  // lineHeight: '1.5';
  margin: '8px 0';
  overflow: auto;

  &:hover {
    border-color: lightgreen; // Light green border on hover
  }
}


@media only screen and (max-width: 908px) {
  .product_container {
    margin-left: 8%; /* Adjust this value to the width of your sidebar */
    margin-right: 10%; /* Adjust this value to the width of your sidebar */

  }
}

// @media only screen and (max-width: 1179) {
//   .product_container {
//     margin-left: 8%; /* Adjust this value to the width of your sidebar */
//     margin-left: 30%; /* Adjust this value to the width of your sidebar */

//   }
// }