

  .BottomBar {
    position: fixed; /* Make the bottom bar fixed */
    bottom: 0; /* Position it at the bottom */
    left: 0;
    right: 0; /* Stretch it across the entire width */
    z-index: 1000; /* Ensure it's on top of other elements */
    background-color: #fff; /* Set the background color */
    padding: 10px; /* Add some padding */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow for visual separation */
  
    @media only screen and (min-width: 900px) {
      display: none; // Hide the bottom bar on large screens
    }

  padding: 20px;

  .center {
    width: 100%; // Ensure the center div takes up 100% of the BottomBar width
    display: flex;
    justify-content: center; // Center the ul within the .center div
    align-items: center;

    ul.dash_links {
      display: flex;
      justify-content: space-evenly; // This will distribute the li's evenly

      list-style-type: none;
      padding: 0;
      margin: 0 auto;
      width: 100%; // Ensure the ul takes up 100% of the .center width

      

      li {

        padding: 5%;
        flex-grow: 1; 
        min-width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
 

        .icon {
          font-size: 24px;
          color: #000000;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          color: #14171a;
        }
      }
    }
  }

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  box-sizing: border-box;
}

.BottomBar ul li:first-child {
  margin-left: 0;
}

.BottomBar ul li:last-child {
  margin-right: 0;
}