.profile {
  display: flex;
  width: 100%;

  .profile_container {
    flex: 6;

    .top {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;

      h2 {
        color: lightgrey;
      }
    }
    // .userInfo {
    //   -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    //   box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    //   padding: 10px;
    //   margin: 20px;
    // }
  }
}
