.export {
  background-color: lightgreen;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
}

@media only screen and (min-width: 1200px) {
  .grid{
    margin-left: 200px; // Hide the sidebar on mobile screens
  }
}


// .MuiDataGrid-cell {
//   border-left: 0.1px solid #ccc;
//   border-right: 0.5px solid #ccc;
// }

.grid_container {
  // box-shadow: none; // Removes any shadow which might emphasize borders
  // border-left: 1px; // Explicitly remove the left border
  font-family: 'Lato', sans-serif !important; // Ensure Lato is used throughout

  .MuiDataGrid-root {
    border-left: 10px !important; // Ensure that the left border of the DataGrid is removed
    // margin-left: 50px;
    // margin-right: 50px;
    // margin-top: 50px;
    font-family: 'Lato', sans-serif !important; // Ensure Lato is used for the DataGrid as well
  }
}

.MuiDataGrid-colCell {
  border-left: 1px solid #ccc; /* Add border to the bottom of header cells */
}
